/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Collapse,
	Flex,
	Icon,
	IconButton,
	position,
	useDisclosure,
	VStack,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MdSort } from 'react-icons/md'
import Scrollbar from 'react-custom-scrollbars-2'
import FilterContainer from '../containers/dashboard/filter-view'
import WidgetData from '../containers/dashboard/widget-workspace/widget-data'
import WidgetView from '../containers/dashboard/widget-workspace/widget-view'
import MobileWorkspacePanel from '../containers/workspace-panel/mobile-workspace-panel'
import { DashboardContextProvider } from '../context/dashboard.context'
import { DrilldownContextProvider } from '../context/drilldown.context'
import { WIDGET_WORKSPACE_MODE } from '../utils/widget-constants'
import { dashboardConst } from '../utils/action.constant'
import { useAuthContext } from '../context/auth.context'
import { sectionDetailedTrack } from '../containers/repository/repo.utils'
import { GA_CATEGORY_DASHBOARD } from './../containers/repository/repo.utils'

export default function MobileDashboardPage(props) {
	const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: false })
	const [view, setView] = useState(WIDGET_WORKSPACE_MODE.WIDGET_VIEW)
	const {
		state: { authData },
		dispatch,
	} = useAuthContext()
	const isMobileView = localStorage.getItem('IS_MOBILE_VIEW')

	useEffect(() => {
		if (view === WIDGET_WORKSPACE_MODE.WIDGET_DATA && !!isOpen) {
			onToggle()
		}
	}, [view])

	const onScrollView = async e => {
		let bottom = e.scrollHeight - e.scrollTop - e.clientHeight <= 1
		if (bottom) {
			dispatch({ type: dashboardConst.SCROLL_BOTTOM })
		}
	}

	return (
		<DashboardContextProvider>
			<DrilldownContextProvider>
				<Flex w='100%' flexDirection={isMobileView ? 'column-reverse' : 'row'}>
					<VStack h='calc(100vh - 30px)' w='100%' align='stretch' mr={3}>
						<Collapse
							in={isOpen}
							endingHeight='auto'
							animateOpacity
							style={{
								overflow: 'unset',
							}}
						>
							<FilterContainer onWidgetViewChange={setView} />
						</Collapse>
						<Box h='100%'>
							<Scrollbar
								onScroll={e => onScrollView(e)}
								style={{ position: isMobileView ? 'absolute' : 'relative' }}
							>
								{view === WIDGET_WORKSPACE_MODE.WIDGET_VIEW ? (
									<WidgetView onWidgetViewChange={setView} />
								) : view === WIDGET_WORKSPACE_MODE.WIDGET_DATA ? (
									<WidgetData
										onWidgetViewChange={setView}
										isMobileView={isMobileView}
									/>
								) : (
									<MobileWorkspacePanel
										isDashboard={true}
										onWidgetViewChange={setView}
										isMobileView={isMobileView}
									/>
								)}
							</Scrollbar>
						</Box>
					</VStack>
					<IconButton
						bg='white'
						borderRadius='md'
						boxShadow='md'
						aria-label='Refresh'
						cursor='pointer'
						size='sm'
						position='fixed'
						right='12px'
						padding='8px'
						top='13px'
						zIndex={'99999'}
						display={
							view === WIDGET_WORKSPACE_MODE.WIDGET_VIEW && isMobileView
								? 'flex'
								: view === WIDGET_WORKSPACE_MODE.WIDGET_VIEW && !isMobileView
								? 'block'
								: 'none'
						}
						justifyContent={isMobileView ? 'end' : ''}
						_focus={{
							bg: 'secondary',
						}}
						active={isOpen}
						_active={{
							bg: 'secondary',
						}}
						_hover={{
							bg: 'secondary',
						}}
						onClick={() => {
							sectionDetailedTrack({
								category: GA_CATEGORY_DASHBOARD,
								action: 'Toggle Filters',
							})
							onToggle()
						}}
						icon={<Icon as={MdSort} w={4} h={4} />}
					/>
				</Flex>
			</DrilldownContextProvider>
		</DashboardContextProvider>
	)
}
