import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import ReportWorkspace from './../containers/report';
export default function ReportPage(props) {
  return (
    <Box>
      <ReportWorkspace />
    </Box>
  );
}
