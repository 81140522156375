/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { usePaymentStatusCheck } from '../hooks/form.hooks'
import {
  Box,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalContent,
  Button,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useDisclosure,
  useToast,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import { uuid } from 'uuidv4'
import {
  usePaymentChanelData,
  useGetSignature,
  useCreateOrder,
  useGatewayrouting,
} from '../hooks/form.hooks'
import OrderInfo from '../components/form/components/Payment/PaymentChanel/OrderInfo';

let displayObj = {
  'Order No': 'merchant_txn_ref_no',
  'Amount': 'amount',
  'GreneOS No': 'transaction_id',
  'PG Refrence No': 'pg_ref_no',
  'Payment DateTime': 'paymentDateTime',
  'Payment Mode': 'payment_inst_type',
  'Payment Status': 'payment_response_desc',
}

let statusCode = {
  '00': "success",
  '21': 'warning',
  '*': 'error'
}
const PaymentPage = () => {
  const urlParams = useParams()
  const history = useHistory()
  const [paymetStatus, setpaymetStatus] = useState({})
  const [gateWayData, setGateWayData] = useState({})
  const [dummy, setdummy] = useState(true);
  const [onPay, setOnPay] = useState(false)

  const { mutate } = usePaymentStatusCheck()
  const { mutate: getSignature } = useGetSignature()
  const { mutate: createOrder } = useCreateOrder()
  const { mutate: geteWayrouting } = useGatewayrouting()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: orderIsOpne, onOpen: OrderOnOpen, onClose: orderOnClose } = useDisclosure()
  const toast = useToast({
    duration: 5000,
  })

  useEffect(() => {
    if (!urlParams?.params) return
    let decoded = atob(urlParams?.params)
    decoded = JSON.parse(decoded)
    mutate(
      {
        merchant_txn_ref_no: decoded.merchant_txn_ref_no,
        merchant_id: decoded.merchant_id,
      },
      {
        onSuccess: async res => {
          console.log(res)
          setpaymetStatus(res)
          onOpen()
        },
        onError: err => {
          toast({
            title: 'Transaction Failed',
            status: 'error',
          })
        },
      }
    )
  }, [])

  const onCloseModal = () => {
    onClose()
    localStorage.removeItem('orderInfo')
    history.push('/files')
  }


  const buildSignatureParams = () => {
    return {
      merchant_id: 'M00001',
      currency: 'INR',
      is_parent: 0,
      activity_id: paymetStatus.activity_id,
      activity_type_id: paymetStatus.activity_type_id,
      merchant_txn_ref_no: uuid(),
      reservation_id: 0,
      amount: toGetFieldValue('Total'),//Total
      description: `paying for ${toGetFieldValue('Plan for')} plan`,
      sub_order_list: [],
      customer_mobile_no: toGetFieldValue('Contact Number'),
      customer_first_name: toGetFieldValue('First Name'),
      customer_last_name: toGetFieldValue('Last Name'),
      customer_emailid: toGetFieldValue('Email Id'),
    }
  }

  const toGetSignature = () => {
    let paramsData = buildSignatureParams()
    getSignature({
      ...paramsData
    }, {
      onSuccess: async (data) => {
        //signature
        if (data.hasOwnProperty('signature') && data.signature) {
          createOrder({
            ...paramsData,
            signature: data.signature
          }, {
            onSuccess: async res => {
              if (!res) return;
              geteWayrouting(
                {
                  ...paramsData,
                  signature: data.signature
                }
                , {
                  onSuccess: async response => {
                    setGateWayData(response);
                    setOnPay(true)
                    setdummy(!dummy)
                    setTimeout(() => {
                      let theForm = document.getElementById('payForm');
                      theForm.submit()
                    }, 1000);
                  }
                })
            }
          })
        }

      }
    })

  }

  let orderInfo = localStorage.getItem('orderInfo') ? JSON.parse(localStorage.getItem('orderInfo')) : [];
  const toGetFieldValue = (field) => {
    return (orderInfo.find(el => el.field_name === field))?.field_value || ''
  }

  return (
    <div>
      {/* payment status checking... */}

      <Modal onClose={onClose} size='lg' isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Payment Status</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {paymetStatus?.payment_response_code && <Alert status={statusCode[paymetStatus?.payment_response_code] ? statusCode[paymetStatus?.payment_response_code] : 'error'}>
              <AlertIcon />
              <AlertTitle>{paymetStatus?.payment_response_desc || 'Transaction Failed'}!</AlertTitle>
            </Alert>}
            <TableContainer>
              <Table size='sm'>
                <Thead>
                  <Tr>
                    <Th>Sl.N</Th>
                    <Th>Key</Th>
                    <Th >Value</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {
                    paymetStatus && Object.keys(displayObj).map((val, i) => (
                      <Tr key={i}>
                        <Td>{i + 1}</Td>
                        <Td>{val}</Td>
                        <Td >{paymetStatus[displayObj[val]]}</Td>
                      </Tr>
                    ))
                  }
                </Tbody>
              </Table>
            </TableContainer>

            <OrderInfo
              isOpen={orderIsOpne}
              onClose={orderOnClose}
              orderInfo={orderInfo}
              onPayNow={toGetSignature}
              onPay={onPay}
            />
          </ModalBody>
          <ModalFooter>
            {
              !(paymetStatus?.payment_response_code === '00' || paymetStatus?.payment_response_code === '21') &&
              <Button
                bg={localStorage.getItem('color')}
                variant="filled"
                color="white"
                borderRadius={6}
                boxShadow="md"
                marginRight='7px'
                onClick={OrderOnOpen}
              // disabled={onPay}
              >Pay Again</Button>
            }
            <Button onClick={onCloseModal}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box>
        <form id='payForm' style={{ visibility: 'hidden' }} name="payForm" action={gateWayData.actionUrl} method="post" enctype="application/x-www-form-urlencoded" >
          {
            Object.keys(gateWayData).length > 0 ?
              Object.keys(gateWayData).map((val, i) => (
                <input type="text" key={i} name={val} value={gateWayData[val]} />
              ))
              : ""
          }
        </form>
      </Box>
    </div>
  )
}

export default PaymentPage
