/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react'
import {
	Box,
	HStack,
	Heading,
	InputGroup,
	Input,
	InputRightElement,
	Icon,
} from '@chakra-ui/react'
import Card from '../components/card'
import RepositoryList from '../containers/repository/repositoryList'
import { debounce } from 'lodash'
import { MdSearch } from 'react-icons/md'
import DocumentsView from '../containers/repository/index'
import CustomMenu from '../components/menu'
import { useLanguageContext } from '../context/locale.context'
import { MdSort } from 'react-icons/md'
import {
	useGetRepositoryList,
	useDocTypeContentList,
} from '../hooks/documents.hooks'
// import { dataLayerTagManager } from '../containers/repository/repo.utils'

export default function RepositoryPage(data) {
	//const { mutate: getRepoListMutate, isLoading, data } = useGetRepositoryList()
	const {
		state: { locale },
	} = useLanguageContext()
	const {
		mutate: getRepoContentMutate,
		isLoading: isFoldersLoading,
		data: selectedRepoData,
	} = useDocTypeContentList()
	const search_filters = [
		{
			label: locale['FILE'],
			key: locale['file'],
			value: 2,
		},
		{
			label: locale['FOLDER'],
			key: locale['folder'],
			value: 1,
		},
	]
	const [searchQuery, setSearchQuery] = useState('')
	const [searchCondition, setSearchCondition] = useState(search_filters[1])
	const [query, setQuery] = useState('')
	const [selectedRepo, setSelectedRepo] = useState(null)
	const onDebounceSearch = useCallback(
		debounce(text => {
			setQuery(text)
		}, 500),
		[]
	)

	// useEffect(() => {
	// 	loadRepository()
	// }, [])

	useEffect(() => {
		onDebounceSearch(searchQuery)
	}, [searchQuery])
	useEffect(() => {
		if (!!searchQuery && searchQuery.length !== 0) {
			setSelectedRepo(null)
		}
	}, [searchQuery])

	const onLoadDocContent = payload => {
		getRepoContentMutate({
			activity_type_id: payload.activity_type_id,
			flag: 1, //- based on activity type id all root folders are return for respective user(shared & Self created)
			page_start: 0,
			page_limit: 100,
			document_repository_id: 0,
			repository_sub_type_id: 2,
		})
	}
	// const loadRepository = () => {
	//   getRepoListMutate({
	//     page_start: 0,
	//     page_limit: 50,
	//     flag: 0,
	//   })
	// }
	const onSearchDoc = text => {
		onDebounceSearch(text)
		setSelectedRepo(null)
	}
	const onRepoSelect = repo => {
		onLoadDocContent(repo)
		setSelectedRepo(repo)
		setSearchQuery('')
	}
	return (
		<>
			<HStack d='flex' alignItems='flex-start' justifyContent='flex-end' mb={2}>
				<Heading as='h4' ml={4} size='md' py='4' flex={1}>
					{/* {locale['Document Repository']} */}
				</Heading>
				<InputGroup maxW='300px'>
					<Input
						bg='white'
						placeholder={`${locale['Search']} ${searchCondition.key}`}
						borderRadius='md'
						variant='filled'
						boxShadow='md'
						value={searchQuery}
						onChange={e => setSearchQuery(e.target.value)}
						onKeyDown={e => {
							if (e.key === 13) {
								// dataLayerTagManager('button_click', {
								// 	viewName: 'Document Repository',
								// 	buttonName: 'Search Document',
								// })
								onSearchDoc(e.target.value)
							}
						}}
						_focus={{
							border: 'none',
						}}
					/>
					<InputRightElement
						children={
							<Icon
								as={MdSearch}
								color={localStorage.getItem('color')}
								w={6}
								h={6}
							/>
						}
					/>
				</InputGroup>
				<CustomMenu
					optionList={search_filters}
					selected={searchCondition}
					isFilter
					onSelect={item => {
						// dataLayerTagManager('button_click', {
						// 	viewName: 'Document Repository',
						// 	buttonName: 'Filter',
						// })
						setSearchCondition(item)
						setSearchQuery('')
					}}
					icon={MdSort}
				/>
			</HStack>

			<HStack alignItems='flex-start'>
				<Card
					minH='calc(100vh - 222px)'
					maxH='calc(100vh - 222px)'
					h='calc(100vh - 222px)'
					w={{ base: '250', md: '300px', lg: '320px' }}
					overflowY='auto'
				>
					<RepositoryList
						repoList={!!data ? data.data : []}
						onSelect={onRepoSelect}
						//isLoading={isLoading}
						selectedRepo={selectedRepo}
					/>
				</Card>
				<Box
					flex={1}
					minH='calc(100vh - 222px)'
					maxH='calc(100vh - 222px)'
					h='calc(100vh - 222px)'
					bg='white'
					borderRadius='md'
					overflowY='scroll'
				>
					<DocumentsView
						accessableRepositories={data}
						parentRepository={selectedRepo}
						isLoading={isFoldersLoading}
						selectedRepoData={selectedRepoData}
						onSelectRoot={onLoadDocContent}
						refreshPage={onLoadDocContent}
						searchType={searchCondition}
						query={query}
					/>
				</Box>
			</HStack>
		</>
	)
}
