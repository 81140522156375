/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import {
  Box,
  Text,
  Button,
  Center,
  HStack,
  VStack,
  useToast,
} from '@chakra-ui/react'
import { useParams, useHistory, Link } from 'react-router-dom'
import { SharedContextProvider } from '../context/shared.context'
import { SmartFormContextProvider } from '../context/smart-form.context'
import SmartFormPanel from '../containers/form-panel/smart-form-panel'
import { useWebLinkContext } from '../routes/weblink/web.route'
import { localizationConst } from '../utils/action.constant'
import {
  useLoadFormFieldList,
  useSendEmail,
  useSendSMS,
} from '../hooks/form.hooks'
import { useUpdateActivityMasterData } from '../hooks/activity.hooks'
import { useGetTranslations } from '../hooks/dashboard.hooks'
import { useLanguageContext } from '../context/locale.context'
import { useAuthContext } from '../context/auth.context'
import Axios from 'axios'
import { getUrlId } from '../utils/common.util'
import { FiCopy } from 'react-icons/fi'

function OriginFormEntry(props) {
  const context = useWebLinkContext()
  const history = useHistory()
  const urlParams = useParams()
  const [urlData, setUrlData] = useState(null)
  const [isOpen, setModalOpen] = useState(false)
  const [timelineData, setTimelineData] = useState({})
  const { mutate, isLoading } = useLoadFormFieldList()
  const { mutate: loadLanguageTexts } = useGetTranslations()
  const { mutate: mutateSendEmail } = useSendEmail()
  const { mutate: masterDataUpdate } = useUpdateActivityMasterData()
  const { mutate: mutateSendSMS } = useSendSMS()

  const toast = useToast({
    duration: 2000,
  })
  const {
    state: { authData },
  } = useAuthContext()

  const {
    state: { locale, selectedLanguage },
    dispatch: languageDispatch,
  } = useLanguageContext()

  useEffect(() => {
    try {
      let url = atob(urlParams?.params)
      let parsed = JSON.parse(url)

      if (parsed.url_form_data) {
        setUrlData({
          ...JSON.parse(parsed.url_form_data),
          ...{ form_name: context.formName, is_external: 'true' },
        })
        context.setOrgId(parsed.organization_id)
      }
    } catch (error) {
      console.log(error)
    }
    return () => {}
  }, [urlParams, context.formName])

  const sendEmailv8 = payload => {
    mutateSendEmail(
      {
        payload,
      },
      {
        onSuccess: async data => {
          if (data.status) {
            toast({
              title: 'E-mail sent successfully!',
              status: 'warning',
              position: 'top-right',
            })
          }
        },
      }
    )
  }

  const sendMailParams = async () => {
    return {
      asset_id: authData.asset_id,
      organization_id: authData.organization_id,
      asset_token_auth: authData.asset_token_auth,
      customer_name: authData.operating_asset_first_name,
      email_subject: context.formName,
      email_body: context.formName,
      email_receivers: [authData.asset_email_id],
      email_sender_name: 'GreneOS',
      outlook_email_integration: 0,
      email_sender: 'support@greneos.com',
      flag: 0,
      email_sender_username: '',
      email_sender_asset_id: authData.asset_id,
      html_template: btoa(`<p>
      Dear <b>${authData?.operating_asset_first_name},</b></p>
      <p>You can now view all updates related to your request - <b>${context.formName}</b> by using the
      following link <br/>
      <a
        style="color: 'blue'"
        href="${timelineData?.webUrl}"
      >
        View Updates
      </a>        
      </p>`),
    }
  }

  const sendMailHandler = async () => {
    const payload = await sendMailParams()
    if (payload) {
      sendEmailv8(payload)
    }
  }

  const sendSMSHandler = async () => {
    mutateSendSMS(
      {
        message: `Dear ${authData.operating_asset_first_name}, a new update - Form has been added to your request - ${context.formName}. Please tap on the below link to view the update ${timelineData?.webUrl}. Please tap on the below link to access the timeline of your request ${timelineData?.webUrl}. Greneos.`,
        country_code: `${authData.asset_phone_country_code}`,
        phone_number: `${authData.asset_phone_number}`,
        organization_id: `${authData.organization_id}`,
      },
      {
        onSuccess: async data => {
          if (data === 200) {
            toast({
              title: 'SMS sent successfully!',
              status: 'warning',
              position: 'top-right',
            })
          }
        },
      }
    )
  }

  useEffect(() => {
    Object.keys(locale).length === 0 &&
      setSelectedLanguage({ label: 'English', value: 1 })
  }, [locale, authData])

  const setSelectedLanguage = selectedLanguage => {
    loadLanguageTexts(
      {
        asset_id: authData?.asset_id,
        asset_token_auth: authData?.asset_token_auth,
        language_id: selectedLanguage.value,
        limit_value: 600,
        start_from: 0,
      },
      {
        onSuccess: data => {
          if (!!data)
            languageDispatch({
              type: localizationConst.SET_SELECTED_LANGUAGE,
              selectedLanguage,
              data,
            })
        },
        onError: err => {
          console.log(err)
        },
      }
    )
  }

  useEffect(() => {
    if (urlData && authData && !!urlData.form_id) {
      mutate(
        {
          organization_id: authData.organization_id,
          account_id: authData.account_id,
          workforce_id: authData.workforce_id,
          form_id: urlData.form_id,
        },
        {
          onSuccess: async data => {
            if (data && data[0]) {
              if (data[0].form_name) {
                context.setFormName(data[0].form_name)
              }
            }
          },
        }
      )
    }
  }, [urlData, authData])

  const createTimeLineParams = data => {
    getURL(data)
  }

  const createDynamicLink = async url => {
    return await Axios.post(
      'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyAWSBRYMxdKQlCl4TNJymMEmve3kPFsrdI',
      {
        dynamicLinkInfo: {
          domainUriPrefix: 'https://greneos.page.link',
          link: url,
          navigationInfo: {
            enableForcedRedirect: true,
          },
        },
        suffix: {
          option: 'SHORT',
        },
      }
    )
  }

  const createFormUpdateUrlParam = activity => {
    return {
      organization_id: authData.organization_id,
      account_id: authData.account_id,
      workforce_id: authData.workforce_id,
      asset_id: authData.asset_id,
      auth_asset_id: authData.asset_id,
      asset_token_auth: authData.asset_token_auth, //"05986bb0-e364-11e8-a1c0-0b6831833754",
      activity_id: !!activity.activity_id ? activity.activity_id : 0,
      activity_type_category_id: !!activity.activity_type_category_id
        ? activity.activity_type_category_id
        : 9,
      activity_stream_type_id: 705,
      form_id: urlData.form_id,
      form_flag_prefill_enabled: 0,
      activity_type_id: urlData.activity_type_id,
      activity_type_id_form: urlData.activity_type_id,
      type: 'approval',
      asset_first_name: urlData.asset_first_name,
      asset_phone_number: authData.asset_phone_number,
      operating_asset_first_name: urlData.operating_asset_first_name,
      s3BucketName: urlData.s3BucketName,
      activity_type_welcome_message: urlData.activity_type_welcome_message,
      activity_type_closing_message: urlData.activity_type_closing_message,
      form_workflow_activity_type_id: urlData.form_workflow_activity_type_id,
    }
  }

  const getURL = async (e = {}) => {
    const updatePayload = createFormUpdateUrlParam(e)
    let data = await getUrlId([], updatePayload)
    let url = process.env.REACT_APP_EXT_URL
    let webUrl = `${url}originform/timeline/${btoa(JSON.stringify(data))}`
    setTimelineData(tdata => ({
      ...tdata,
      webParams: `/originform/timeline/${btoa(JSON.stringify(data))}`,
    }))
    const res = await createDynamicLink(webUrl)
    if (!!res.data.shortLink)
      try {
        let master_data = !!e.activity_master_data
          ? JSON.parse(e.activity_master_data)
          : {}
        let params = {
          activity_id: !!e.activity_id ? e.activity_id : 0,
          activity_master_data: JSON.stringify({
            ...master_data,
            activity_timeline_url: res.data.shortLink,
          }),
        }
        masterDataUpdate(params, {
          onSuccess: async data => console.log('master data: ', data),
          onError: async err => console.log('err: ', err),
        })
      } catch (err) {
        console.log(err)
      }
    setTimelineData(tdata => ({
      ...tdata,
      webUrl: res.data.shortLink,
    }))
  }

  return (
    <Box bg="white" w={'full'} borderRadius="md" p={2}>
      <SmartFormContextProvider>
        {urlData && !isOpen && (
          <SmartFormPanel
            onClose={e => {
              if (e) {
                createTimeLineParams(e)
              }
              setModalOpen(true)
              // alert(`Form Saved Successfully! ${urlData?.activity_type_closing_message || ""}`)
              // history.push('/files')
            }}
            formData={urlData}
            activity={{}}
            handleError={() => {
              console.log('error occurred!')
            }}
            refillView={null}
            isBulkEdit={false}
            height="calc(100vh - 130px)"
            isOnFly={true}
            isOrigin={true}
            activity_flag_created_by_bot={2}
          />
        )}
      </SmartFormContextProvider>
      {!!isOpen && (
        <Center bg="white" h="100px">
          <VStack>
            <Text fontWeight={'bold'} fontSize={18}>
              {urlData?.activity_type_closing_message || 'Alert'}
            </Text>
            <Text d={'flex'}>
              Dear {authData?.operating_asset_first_name}, You can now view all
              updates related to your request - {context.formName} by using the
              following link{' '}
              {!!timelineData?.webParams && (
                <Link
                  style={{ color: 'blue', marginLeft: 5 }}
                  to={`${timelineData?.webParams}`}
                >
                  {' '}
                  View Updates
                </Link>
              )}
              {!!timelineData.webUrl && (
                <FiCopy
                  size={15}
                  style={{ marginLeft: 5, cursor: 'pointer' }}
                  onClick={e => {
                    navigator.clipboard
                      .writeText(timelineData?.webUrl)
                      .then(() => {
                        toast({
                          title: 'URL Copied Successfully',
                          status: 'warning',
                          position: 'top-right',
                        })
                      })
                      .catch(() => {
                        toast({
                          title: 'Something went wrong',
                          status: 'error',
                          position: 'top-right',
                        })
                      })
                    e.stopPropagation()
                  }}
                />
              )}
            </Text>
            <HStack>
              <Button
                isDisabled={!timelineData?.webUrl}
                colorScheme={localStorage.getItem('color')}
                bg={localStorage.getItem('color')}
                mr={3}
                onClick={sendSMSHandler}
              >
                Send SMS
              </Button>
              <Button
                isDisabled={!timelineData?.webUrl}
                colorScheme={localStorage.getItem('color')}
                bg={localStorage.getItem('color')}
                mr={3}
                onClick={sendMailHandler}
              >
                Send Email
              </Button>
            </HStack>
          </VStack>
        </Center>
      )}
    </Box>
  )
}

export default OriginFormEntry
