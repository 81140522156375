/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import {
  Box,
  Text,
  Button,
  Center,
  HStack,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import { useParams, useHistory, Link } from 'react-router-dom'
import { useSharedContext } from '../context/shared.context'
import { SmartFormContextProvider } from '../context/smart-form.context'
import { useWebLinkContext } from '../routes/weblink/web.route'
import { localizationConst } from '../utils/action.constant'
import { useLoadFormFieldList } from '../hooks/form.hooks'
import { useGetTranslations } from '../hooks/dashboard.hooks'
import { useLanguageContext } from '../context/locale.context'
import Timeline from '../components/timeline'
import TimelineBottomBar from '../containers/workspace-panel/team-collaboration/timeline/bottomBar'
import Card from '../components/card'
import { useAuthContext } from '../context/auth.context'
import TimelineAttachment from '../containers/workspace-panel/team-collaboration/timeline-attachments'
import FormEditPanel from '../containers/form-edit-panel'

function OriginFormTimeline(props) {
  const context = useWebLinkContext()
  const history = useHistory()
  const urlParams = useParams()
  const [urlData, setUrlData] = useState(null)
  const [fileAccessTyp, setfileAccessTyp] = useState('')
  const [replyData, setReplyData] = useState(null)
  const [openFormView, setopenFormView] = useState(null)

  const { isOpen, onClose, onOpen } = useDisclosure()
  const {
    isOpen: isReply,
    onClose: onCloseReply,
    onOpen: onOpenReply,
  } = useDisclosure()
  const {
    isOpen: isFormEditView,
    onOpen: onOpenFormEditView,
    onClose: onCloseFormEdit,
  } = useDisclosure()
  const { mutate, isLoading } = useLoadFormFieldList()
  const { mutate: loadLanguageTexts } = useGetTranslations()
  const {
    state: { authData },
  } = useAuthContext()

  const {
    state: { locale, selectedLanguage },
    dispatch: languageDispatch,
  } = useLanguageContext()

  const {
    state: { timelineFilter },
  } = useSharedContext()

  useEffect(() => {
    try {
      let url = atob(urlParams?.params)
      let parsed = JSON.parse(url)

      if (parsed.url_form_data) {
        setUrlData({
          ...JSON.parse(parsed.url_form_data),
          ...{ form_name: context.formName, is_external: 'true' },
        })
        context.setOrgId(parsed.organization_id)
      }
    } catch (error) {
      console.log(error)
    }
    return () => {}
  }, [urlParams, context.formName])

  useEffect(() => {
    Object.keys(locale).length === 0 &&
      setSelectedLanguage({ label: 'English', value: 1 })
  }, [locale, authData])

  const setSelectedLanguage = selectedLanguage => {
    loadLanguageTexts(
      {
        asset_id: authData?.asset_id,
        asset_token_auth: authData?.asset_token_auth,
        language_id: selectedLanguage.value,
        limit_value: 600,
        start_from: 0,
      },
      {
        onSuccess: data => {
          if (!!data)
            languageDispatch({
              type: localizationConst.SET_SELECTED_LANGUAGE,
              selectedLanguage,
              data,
            })
        },
        onError: err => {},
      }
    )
  }

  useEffect(() => {
    if (urlData && authData && !!urlData.form_id) {
      mutate(
        {
          organization_id: authData.organization_id,
          account_id: authData.account_id,
          workforce_id: authData.workforce_id,
          form_id: urlData.form_id,
        },
        {
          onSuccess: async data => {
            if (data && data[0]) {
              if (data[0].form_name) {
                context.setFormName(data[0].form_name)
              }
            }
          },
        }
      )
    }
  }, [urlData, authData])
  console.log({ urlData })
  const isOverviewOpen = false

  const timelineReplyData = (data = null) => {
    setReplyData(data)
    if (!!data) {
      onOpenReply()
    } else {
      onCloseReply()
    }
  }

  const onOpenAttachment = type => {
    setfileAccessTyp(type)
    onOpen()
  }

  return (
    <Box bg="white" w={'full'} borderRadius="md" p={2}>
      <SmartFormContextProvider>
        {urlData && (
          <Card
            w="100%"
            h="100%"
            position="relative"
            className={`team-collaborations-container`}
          >
            {isOpen ? (
              <TimelineAttachment
                isOpen={isOpen}
                onClose={onClose}
                activity={urlData}
                fileAccessTyp={fileAccessTyp}
              />
            ) : null}
            {isFormEditView ? (
              <FormEditPanel
                isOpen={isFormEditView}
                onClose={onCloseFormEdit}
                activity={urlData}
                formData={openFormView}
                refillForm={() => null}
                isFormEnable={false}
              />
            ) : null}
            <VStack w="100%">
              <VStack
                className={`team-collaborations-timeline-container`}
                w="100%"
                style={{
                  transformOrigin: 'bottom',
                  transition: 'transform 0.26s ease',
                }}
                maxH={
                  isOverviewOpen ? 'calc(100vh - 370px)' : 'calc(100vh - 170px)'
                }
                minH={
                  isOverviewOpen ? 'calc(100vh - 370px)' : 'calc(100vh - 170px)'
                }
                flex={1}
              >
                <Timeline
                  onFormSelection={f => {
                    const { activity_id, activity_type_id } = urlData
                    setopenFormView({
                      ...f,
                      disableEdit: true,
                      activity_id,
                      activity_type_id,
                    })
                    onOpenFormEditView()
                  }}
                  activity={urlData}
                  filter={timelineFilter}
                  timelineReplyData={timelineReplyData}
                />
              </VStack>
              <TimelineBottomBar
                activity={urlData}
                openAttachment={onOpenAttachment}
                onOpenParticipantView={() => {}}
                isReplyOn={isReply}
                timelineReplyData={timelineReplyData}
                replyData={replyData}
                onOpenSendEmail={() => {}}
                fromExternal={true}
              />
            </VStack>
          </Card>
        )}
      </SmartFormContextProvider>
    </Box>
  )
}

export default OriginFormTimeline
